<template>
	<div>
		<div v-for="item in uAddress" :key="item.id">
			<h2>{{ item.firstName }} {{ item.lastName }}</h2>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="alert alert-danger" v-if="error">{{ error }}</div>
				<div class="alert alert-success" v-if="success">{{ success }}</div>
				<b-form @submit.prevent="updateAddress" ref="form">
					<div v-for="item in uAddress" :key="item.id" class="panel p-2">
						<input type="hidden" name="id" :value="item.id" />
						<div class="row">
							<div class="col-lg-6">
								<h5>{{ $t("Vorname") }}:</h5>
								<b-form-input name="firstName" autocomplete="off" :value="item.firstName"></b-form-input>
							</div>
							<div class="col-lg-6">
								<h5>{{ $t("Nachname") }}:</h5>
								<b-form-input name="lastName" autocomplete="off" :value="item.lastName"></b-form-input>
							</div>
						</div>
						<div class="row">
							<div class="col-lg-3">
								<h5>{{ $t("PLZ") }}:</h5>
								<b-form-input name="postCode" autocomplete="off" :value="item.postCode"></b-form-input>
							</div>
							<div class="col-lg-9">
								<h5>{{ $t("Stadt") }}:</h5>
								<b-form-input name="city" autocomplete="off" :value="item.city"></b-form-input>
							</div>
						</div>
						<h5>{{ $t("Strasse") }}:</h5>
						<b-form-input name="street" autocomplete="off" :value="item.street"></b-form-input>
						<h5>{{ $t("Adress Zusatz") }}:</h5>
						<b-form-input name="address" autocomplete="off" :value="item.address"></b-form-input>
						<h5>{{ $t("Telefon") }}:</h5>
						<b-form-input name="phone" autocomplete="off" :value="item.phone"></b-form-input>
					</div>
					<div class="text-right">
						<button class="btn btn-md btn-right btn-primary mt-2 mx-0" type="submit" name="button">Update</button>
					</div>
				</b-form>
				<button class="btn btn-lg btn-block btn-primary mt-3 mx-0" type="button" @click="$router.go(-1)">{{ $t("Zurück") }}</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import DataService from "@/services/DataService";
	export default {
		name: "Address",
		props: {
			addressId: {
				type: Number,
			},
		},
		data() {
			return {
				uAddress: Object,
				form: {
					id: "",
					lastName: "",
					firstName: "",
					street: "",
					address: "",
					postCode: "",
					phone: "",
					city: "",
				},
				error: null,
				success: null,
			};
		},
		created: function() {
			DataService.getUser(this.companyData.apiKey, this.userData.token).then((response) => {
				this.uAddress = response.data.addresses.filter((item) => item.id == this.$route.params.addressId);
			});
		},
		computed: {
			...mapGetters(["userData", "companyData"]),
		},
		methods: {
			updateAddress() {
				DataService.userAddress({
					id: this.$route.params.addressId,
					lastName: this.$refs.form.lastName.value,
					firstName: this.$refs.form.firstName.value,
					street: this.$refs.form.street.value,
					address: this.$refs.form.address.value,
					postCode: this.$refs.form.postCode.value,
					phone: this.$refs.form.phone.value.replace(/[- )(]/g, ""),
					city: this.$refs.form.city.value,
				})
					.then(() => {
						this.success = "Update Successful!";
						this.error = false;
						setTimeout(() => {
							this.$router.go(-1);
						}, 1000);
					})
					.catch((err) => {
						this.error = err.response.data;
					});
			},
			goBack() {
				setTimeout(() => {
					this.$router.go(-1);
				}, 300);
			},
		},
	};
</script>

<style scoped>
	h5 {
		margin-top: 5px;
	}
</style>
