<template>
	<div>
		<div class="row">
			<div class="col-md-12">
				<div class="item-image">
					<img class="w-100 rounded" v-bind:src="item.imageUrl" @error="replaceByDefault" />
				</div>
				<div class="item-info">
					<div class="p-0 mt-1">
						<em
							><b>{{ item.quantity }}</b></em
						>
						x {{ productName(item.name) }}
						<span class="item-sides price">(@{{ item.price | currency(cSymb) }})</span>
					</div>
					<div v-for="item in item.selectedOptions" :key="item.id">
						<div v-for="subItem in item.options" :key="subItem.id">
							<div class="item-sides p-0">
								incl. {{ subItem.optionQuantity }} x
								<span v-if="langIndex == 1 && subItem.name1">{{ subItem.name1 }}</span>
								<span v-else-if="langIndex == 2 && subItem.name2">{{ subItem.name2 }}</span>
								<span v-else-if="langIndex == 3 && subItem.name3">{{ subItem.name3 }}</span>
								<span v-else>{{ subItem.name }}</span>
								<span class="price">(@{{ subItem.addPrice | currency(cSymb) }})</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div v-if="item.note">
					<BaseIcon name="paperclip" width="16" height="16" class="mt-0" /> <i>{{ item.note }}</i>
				</div>
				<div class="row mx-1 d-flex justify-content-between my-1">
					<div class="m-0">
						<div class="item-total p-0">
							Total: <span class="color">{{ item.totalPrice | currency(cSymb) }}</span>
						</div>
					</div>
					<div class="m-0 p-0" style="cursor: pointer">
						<RemoveFromCart :product="item" />
					</div>
				</div>
			</div>
		</div>
		<hr />
	</div>
</template>

<script>
	import RemoveFromCart from "@/components/shopping-cart/RemoveFromCart";
	import DataService from "@/services/DataService";
	import { mapState, mapGetters } from "vuex";
	import Vue from "vue";

	export default {
		name: "ShoppingCartItem",
		props: {
			item: Object,
		},
		components: { RemoveFromCart },
		computed: {
			...mapGetters(["companyData"]),
			...mapState({
				products: (state) => state.products.all,
			}),
			cSymb() {
				if (this.companyData.storeCurrency === "EUR") {
					return "€";
				} else if (this.companyData.storeCurrency === "USD") {
					return "$";
				} else {
					return this.companyData.storeCurrency;
				}
			},
			langIndex() {
				var lang = Vue.i18n.locale();
				var index = this.companyData.languages.findIndex((x) => x === lang);
				return index;
			},
		},
		methods: {
			replaceByDefault(e) {
				e.target.src = "/assets/img/no_image.png";
			},
			GetArticleImageUrl(id) {
				return DataService.getArticleImageUrl(id);
			},
			productName(m) {
				var lang = Vue.i18n.locale();
				var index = this.companyData.languages.findIndex((x) => x === lang);
				var gn = this.products.find((item) => item.name === m);
				if (index == 1 && gn.name1 !== "") {
					return gn.name1;
				} else if (index == 2 && gn.name2 !== "") {
					return gn.name2;
				} else if (index == 3 && gn.name3 !== "") {
					return gn.name3;
				} else {
					return gn.name;
				}
			},
		},
	};
</script>

<style scoped>
	.price {
		white-space: nowrap;
	}
</style>
