import axios from "axios";
import NProgress from "nprogress";

const apiClient = axios.create({
	baseURL: "https://selfapi.xenia-pos.net/api/",
});

apiClient.interceptors.request.use((config) => {
	NProgress.configure({
		showSpinner: false,
	});
	NProgress.start();
	return config;
});

apiClient.interceptors.response.use((response) => {
	NProgress.done();
	return response;
});

export default {
	getCompanyList(key) {
		apiClient.defaults.headers.common["x-api-key"] = key;
		return apiClient.get("/Company");
	},

	setStore(key) {
		apiClient.defaults.headers.common["x-api-key"] = key;
	},

	getAllProducts() {
		return apiClient.get("/Article");
	},

	getStore(key) {
		apiClient.defaults.headers.common["x-api-key"] = key;
		return apiClient.get("/Setup");
	},

	getStoreBlog() {
		return apiClient.get("/Blog");
	},

	getStoreLegal() {
		return apiClient.get("/Blog/imprint");
	},

	getStorePrivacy() {
		return apiClient.get("/Blog/gdpdu");
	},

	createOrder(cart_content) {
		return apiClient.post("/CreateOrder", cart_content);
	},

	getDeliveryCost(cart_content) {
		return apiClient.post("/DeliveryCosts", cart_content);
	},

	userRegister(credentials) {
		return apiClient.post("/Register", credentials);
	},

	activateUser(credentials) {
		return apiClient.post("/Activate/" + credentials);
	},

	setHeaders(token) {
		apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	},

	getUser(key, token) {
		apiClient.defaults.headers.common["x-api-key"] = key;
		apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		return apiClient.get("/User");
	},

	getUserState(key, token) {
		apiClient.defaults.headers.common["x-api-key"] = key;
		apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		return apiClient.get("/UserState");
	},

	getOrders(key, token) {
		apiClient.defaults.headers.common["x-api-key"] = key;
		apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		return apiClient.get("/Orders");
	},

	updateUser(credentials) {
		return apiClient.put("/User", credentials);
	},

	userLogin(credentials) {
		return apiClient.post("/Login", credentials);
	},

	pwdReset(credentials) {
		return apiClient.post("/ResetPassword/" + credentials);
	},

	userAddress(credentials) {
		return apiClient.post("/DeliveryAddress", credentials);
	},
};
