import Vue from "vue";
import Router from "vue-router";
import Order from "./views/Order.vue";
import Stores from "./views/Stores.vue";
import Store from "./views/Store.vue";
import Legal from "./views/Legal.vue";
import Privacy from "./views/Privacy.vue";
import Product from "./views/ProductPage.vue";
import Cart from "./views/CartPage.vue";
//import CheckOut from "./views/CheckOut.vue";
import RegisterUser from "./views/Register.vue";
import LoginUser from "./views/Login.vue";
import ResetPassword from "./views/ResetPassword.vue";
import Account from "./views/Account.vue";
import UserAddress from "./views/UserAddress.vue";
import NewAddress from "./views/NewAddress.vue";
import Orders from "./views/Orders.vue";
import PosList from "./views/PosList.vue";
import PaymentSuccess from "./views/PaymentSuccess.vue";
import v27gCDJx0G6r from "./views/v27gCDJx0G6r.vue";

const CheckOut = () => import(/* webpackChunkName: "checkout" */ "./views/CheckOut.vue");

Vue.use(Router);

const router = new Router({
	mode: "history",
	//base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "order",
			component: Order,
			props: true,
			beforeEnter(to, from, next) {
				if (to.query.reset) {
					window.localStorage.clear();
					next({ name: "stores" });
				} else {
					next();
				}
			},
		},
		{
			path: "/stores",
			name: "stores",
			component: Stores,
			meta: {
				hideNavbarTop: true,
				hideLogin: true,
			},
		},
		{
			path: "/store",
			name: "store",
			component: Store,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/legal",
			name: "legal",
			component: Legal,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/privacy",
			name: "privacy",
			component: Privacy,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/product/:productId",
			name: "productPage",
			component: Product,
			meta: {
				requiresStore: true,
			},
			props: true,
		},
		{
			path: "/cart",
			name: "CartPage",
			component: Cart,
			meta: {
				requiresStore: true,
			},
			props: true,
		},
		{
			path: "/checkout",
			name: "CheckOut",
			component: CheckOut,
			meta: {
				requiresStore: true,
			},
			props: true,
		},
		{
			path: "/register",
			name: "register",
			component: RegisterUser,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/login",
			name: "login",
			component: LoginUser,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/resetpwd",
			name: "resetpwd",
			component: ResetPassword,
			meta: {
				requiresStore: true,
			},
		},
		{
			path: "/account",
			name: "account",
			component: Account,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
		},
		{
			path: "/address/:addressId",
			name: "userAddress",
			component: UserAddress,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
		},
		{
			path: "/newaddress",
			name: "newAddress",
			component: NewAddress,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
		},
		{
			path: "/orders",
			name: "orders",
			component: Orders,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
		},
		{
			path: "/poslist/:orderId",
			name: "posList",
			component: PosList,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
			props: true,
		},
		{
			path: "/paysuccess",
			name: "paymentSuccess",
			component: PaymentSuccess,
			meta: {
				requiresAuth: true,
				requiresStore: true,
			},
		},
		{
			path: "/v27gCDJx0G6r",
			name: "v27gCDJx0G6r",
			component: v27gCDJx0G6r,
		},
	],
});

router.beforeEach((to, from, next) => {
	const inStore = localStorage.getItem("company");
	const loggedIn = localStorage.getItem("user");
	if (to.matched.some((record) => record.meta.requiresStore) && !inStore) {
		next({ name: "stores" });
	} else {
		if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
			next({ name: "login" });
		} else {
			next();
		}
	}
});

export default router;
