<template>
  <div class="header_button">
    <BaseIcon
      name="shopping-cart"
      width="24"
      height="24"
      class="header_icon mr-1"
    />
    <div class="itemsQuantity">({{ itemsQuantity }})</div>
    {{ total | currency(cSymb) }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters(["itemsQuantity", "taxes", "total", "companyData"]),
    cSymb() {
      if (this.companyData.storeCurrency === "EUR") {
        return "€";
      } else if (this.companyData.storeCurrency === "USD") {
        return "$";
      } else {
        return this.companyData.storeCurrency;
      }
    },
  },
};
</script>
<style scoped></style>
