<template>
	<div v-if="companyData.length != 0">
		<closed v-if="onDuty != 'open'" />
		<orderView v-if="inStore" />
	</div>
</template>

<script>
	import orderView from "@/components/orderView.vue";
	import closed from "@/components/closed.vue";
	import { mapGetters } from "vuex";
	import Vue from "vue";
	import axios from "axios";

	export default {
		name: "OrderPage",
		components: {
			orderView,
			closed,
		},
		computed: {
			...mapGetters(["companyData"]),
			onDuty() {
				return this.companyData.onDuty;
			},
			inStore() {
				const inStore = localStorage.getItem("company");
				if (inStore && inStore != 0) {
					return true;
				} else {
					return false;
				}
			},
		},
		created() {
			if (this.$route.query.lang) {
				Vue.i18n.set(this.$route.query.lang);
			}
			if (this.inStore) {
				this.companyData.languages.forEach(function(index) {
					if (index != "") {
						axios.get("/locale/" + index + ".json?v=" + Date.now() + "").then((response) => {
							Vue.i18n.add(index, response.data);
						});
					}
				});
			} else {
				if (!Vue.i18n.locale()) {
					Vue.i18n.set(this.companyData.defaultLanguage);
				}
			}
		},
		beforeMount() {
			const inStore = localStorage.getItem("company");
			if (this.$route.query.apikey) {
				const payload2 = {
					apikey: this.$route.query.apikey,
					allow_stores: false,
				};
				this.$store.dispatch("setStore", payload2);
			} else if (inStore && inStore != 0) {
				const payload3 = {
					apikey: this.companyData.apiKey,
					allow_stores: true,
				};
				this.$store.dispatch("setStore", payload3);
			} else {
				this.$router.push({ name: "stores" });
			}
		},
	};
</script>
