<template>
	<div>
		<BaseIcon name="shopping-cart" width="30" height="30" class="my-2 mr-2" />
		<h1>{{ $t("Warenkorb") }}</h1>
		<div class="row">
			<div class="col-lg-12 px-1 py-1">
				<ShoppingCart />
			</div>
		</div>
	</div>
</template>

<script>
	import ShoppingCart from "@/components/shopping-cart/ShoppingCart";
	import { mapActions } from "vuex";

	export default {
		name: "CartPage",
		components: {
			ShoppingCart,
		},
		props: {
			groupName: {
				type: String,
			},
			productList: {
				type: Array,
			},
		},
		methods: {
			...mapActions({
				removeAllFromCart: "removeAllFromCart",
			}),
		},
		watch: {},
		computed: {
			productGroup() {
				return this.productList;
			},
		},
	};
</script>
