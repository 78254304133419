<template>
	<div>
		<div v-for="product in spGroupName" :key="product.groupName" @click="$router.go(-1)">
			<h2 v-if="translate == 1 && product.groupName1">
				{{ $t(product.groupName1) }}
			</h2>
			<h2 v-else-if="translate == 2 && product.groupName2">
				{{ $t(product.groupName2) }}
			</h2>
			<h2 v-else-if="translate == 3 && product.groupName3">
				{{ $t(product.groupName3) }}
			</h2>
			<h2 v-else>{{ $t(product.groupName) }}</h2>
		</div>
		<div class="row">
			<div class="col-lg-12 p-1" v-for="product in singleProduct" :key="product.id">
				<singleProduct :product="product" />
			</div>
		</div>
	</div>
</template>

<script>
	import singleProduct from "@/components/singleProduct";
	import { mapActions, mapState, mapGetters } from "vuex";
	import Vue from "vue";
	export default {
		name: "ProductPage",
		components: {
			singleProduct,
		},
		created() {
			this.getProducts();
		},
		props: {
			productId: {
				type: Number,
			},
		},
		methods: {
			...mapActions({ getProducts: "getProducts" }),
		},
		computed: {
			...mapState({
				products: (state) => state.products.all,
			}),
			...mapGetters(["companyData"]),
			translate() {
				var lang = Vue.i18n.locale();
				var index = this.companyData.languages.findIndex((x) => x === lang);
				return index;
			},
			spGroupName() {
				return this.products.filter((item) => item.id == this.productId);
			},
			singleProduct() {
				var data = this.products.filter((item) => item.id == this.productId);
				var result = data.filter((obj, pos, arr) => {
					return arr.map((mapObj) => mapObj.name).indexOf(obj.name) == pos;
				});
				return result;
			},
		},
	};
</script>

<style scoped>
	h1 {
		cursor: pointer;
	}
</style>
