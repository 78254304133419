import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

import * as actions from "@/store/modules/actions";
import * as getters from "@/store/modules/getters";
import products from "@/store/modules/products";
import users from "@/store/modules/users";
import companies from "@/store/modules/companies";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store();
const strict = process.env.NODE_ENV !== "production";

Vue.use(vuexI18n.plugin, store);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions,
  getters,
  modules: {
    products,
    users,
    companies,
  },
  plugins: [createPersistedState()],
  strict,
});
