import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";

import VueCurrencyFilter from "vue-currency-filter";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import vueHeadful from "vue-headful";
import VueScrollTo from "vue-scrollto";

import "./plugins/bootstrap-vue";
import "./assets/css/default.scss";
import "./registerServiceWorker";
import * as Resize from "iframe-resizer";

import axios from "axios";

Vue.component("vue-headful", vueHeadful);

Vue.use(Resize);

axios.get("/assets/config.json").then((response) => {
  var currencySymbol = response.data.currencySymbol;
  Vue.use(VueCurrencyFilter, {
    symbol: currencySymbol,
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "back",
    symbolSpacing: true,
  });
});

Vue.use(VueScrollTo);

Vue.config.productionTip = false;

const requireComponent = require.context(
  "./components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});

fetch("/assets/config.json")
  .then((res) => res.json())
  .then((config) => {
    new Vue({
      data() {
        return config;
      },
      router,
      store,
      created() {
        const compString = localStorage.getItem("company");
        const userString = localStorage.getItem("user");
        if (userString) {
          const userData = JSON.parse(userString);
          this.$store.commit("SET_USER_DATA", userData);
        }
        if (compString) {
          const compData = JSON.parse(compString);
          this.$store.commit("SET_STORE", compData);
        }
      },
      render: (h) => h(App),
    }).$mount("#app");
  });
