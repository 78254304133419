<template>
	<div>
		<div class="row mb-4">
			<div class="col-lg-12"></div>
			<div v-for="item in languages" :key="item" :value="item">
				<button :class="item === selectedLang ? 'active' : ''" class="btn btn-md btn-primary mt-3 mx-1 lang" type="button" @click="set_lang(item)">
					{{ translate(item) }}
				</button>
			</div>
			<button class="btn btn-md btn-secondary mt-3 mx-1" type="button" @click="download()">Update</button>
		</div>
		<div class="row">
			<div class="col-lg-12 langedit">
				<form id="form" @submit.prevent="update" autocomplete="off">
					<div class="langedit p-2 mb-4">
						<div v-for="(item, index) in lang" :key="index">
							<div class="langedit-color mt-2">
								<strong>{{ index }}</strong>
							</div>
							<b-form-input :v-model="index" :name="index" :autocomplete="index" :value="item"></b-form-input>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import axios from "axios";

	export default {
		name: "v27gCDJx0G6r",
		data() {
			return {
				selectedLang: "",
				lang: {},
				dl: {},
			};
		},
		created() {
			this.selectedLang = this.companyData.defaultLanguage;
			axios.get("/locale/" + this.selectedLang + ".json?v=" + Date.now() + "").then((response) => {
				this.lang = response.data;
			});
		},
		computed: {
			...mapGetters(["companyData"]),
			languages() {
				var xxx = [];
				this.companyData.languages.forEach(function(index) {
					if (index != "") {
						xxx.push(index);
					}
				});
				return xxx;
			},
		},
		methods: {
			set_lang(val) {
				axios.get("/locale/" + val + ".json?v=" + Date.now() + "").then((response) => {
					this.lang = response.data;
					this.selectedLang = val;
				});
			},
			translate(val) {
				if (val === "de") {
					return "Deutsch";
				} else if (val === "en") {
					return "English";
				} else if (val === "it") {
					return "Italiano";
				} else if (val === "es") {
					return "Español";
				} else if (val === "fr") {
					return "Français";
				} else if (val === "ar") {
					return "عربى";
				} else if (val === "ru") {
					return "Pусский";
				} else if (val === "hr") {
					return "Hrvatski";
				} else if (val === "zh-CN") {
					return "中国人";
				} else {
					return val;
				}
			},
			download() {
				var form = document.getElementById("form");
				var formData = new FormData(form);
				//for (var pair of formData.entries()) {
				//	console.log(pair[0] + ", " + pair[1]);
				//}
				let data = {};
				for (let [key, prop] of formData) {
					data[key] = prop;
				}
				data = JSON.stringify(data, null, 2);
				console.log(data);

				const options = {
					method: "POST",
					headers: { "content-type": "application/form-data" },
					data: {
						jsonData: data,
						fileName: this.selectedLang + ".json",
					},
					url: "/locale/ajax.php?v=" + Date.now() + "",
				};

				axios(options)
					.then(function(response) {
						if (!response.data) {
							alert("File not uploaded.");
						} else {
							alert("File uploaded successfully.");
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		},
	};
</script>

<style scoped>
	.langedit {
		background-color: #ffffff !important;
	}
	.langedit-color {
		color: #00aa2b !important;
	}
	.lang {
		min-width: 100px;
		border-width: 2px;
	}
	.lang.active {
		background-color: var(--shopaccent5) !important;
		border-width: 2px;
	}
</style>
