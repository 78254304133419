<template>
	<div>
		<vue-headful title="Xeno Data GmbH" />
		<div v-if="inputStore">
			<div v-for="item in companies" :key="item.apiKey">
				<div class="row panel pointer mx-0 my-2 p-2" @click="selectStore(item.apiKey)">
					<div class="col-lg-6 p-2 panel t1">
						<div class="text-left">
							<img v-bind:src="item.logoUrl" class="w-100" />
						</div>
					</div>
					<div class="col-lg-6 p-2 panel t1">
						<div class="text-center p-1 m-1">
							<h5>{{ item.shopDescription }}</h5>
						</div>
						<div class="text-center p-1 m-1">
							<h3>{{ item.companyName }}</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div>
				<h2 class="text-center">
					{{ $t("Zur Karte scannen Sie bitte den Tisch QRCode") }}
				</h2>
				<div class="row">
					<div class="col-lg-12">
						<b-form @submit.prevent="submitApiKey">
							<div class="panel t1 p-2 mb-4">
								<div class="scanWindow">
									<qrcode-stream @decode="onDecode" />
								</div>
							</div>
						</b-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import DataService from "@/services/DataService.js";
	import { QrcodeStream } from "vue-qrcode-reader";
	import axios from "axios";
	import Vue from "vue";

	export default {
		name: "Stores",
		data() {
			return {
				companies: [],
				inputStore: true,
				result: "",
				error: "",
			};
		},
		components: {
			QrcodeStream,
		},
		methods: {
			...mapGetters(["companyData"]),
			selectStore(key) {
				const payload1 = { apikey: key, allow_stores: true };
				this.$store.dispatch("setStore", payload1).then(this.$router.push({ name: "order" }));
			},
			onDecode(result) {
				this.result = result;
				var arr = this.result.split(",");
				const payload2 = {
					apikey: arr[0],
					allow_stores: true,
					tableNumber: arr[1],
				};
				this.$store.dispatch("setStore", payload2).then(this.$router.push({ name: "order" }));
			},
		},
		created() {
			Vue.i18n.set(this.$root.default_lang);
			if (this.$route.query.lang) {
				axios.get("/locale/" + this.$route.query.lang + ".json?v=" + Date.now() + "").then((response) => {
					Vue.i18n.set(this.$route.query.lang);
					Vue.i18n.add(this.$route.query.lang, response.data);
				});
			} else {
				axios.get("/locale/" + this.$root.default_lang + ".json?v=" + Date.now() + "").then((response) => {
					Vue.i18n.set(this.$root.default_lang);
					Vue.i18n.add(this.$root.default_lang, response.data);
				});
			}
			if (this.$root.compKey && this.$root.compKey !== "") {
				this.inputStore = true;
				DataService.getCompanyList(this.$root.compKey).then((response) => {
					this.companies = response.data;
				});
			} else {
				this.inputStore = false;
			}
		},
		beforeMount() {
			const inStore = localStorage.getItem("company");
			DataService.getCompanyList(this.$root.compKey).then((response) => {
				if (response.data.length == 1 && !inStore) {
					this.selectStore(response.data[0].apiKey);
				}
			});
		},
	};
</script>
<style scoped>
	.pointer {
		cursor: pointer;
	}
	.scanWindow {
		position: relative;
		width: 100%;
		margin: auto;
		overflow: hidden;
		border: 1px solid #cccccc;
		background-color: #3e3c49;
	}
</style>
