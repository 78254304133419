<template>
	<div>
		<h2>{{ $t("Anmeldung") }}</h2>
		<div class="row">
			<div class="col-lg-12">
				<div class="alert alert-danger" v-if="error">{{ $t(error) }}</div>
				<b-form @submit.prevent="login">
					<div class="panel p-2 mb-4">
						<h5>{{ $t("Email") }}:</h5>
						<b-form-input v-model="email" type="email" name="email" autocomplete="current-email" value></b-form-input>
						<h5>{{ $t("Passwort") }}:</h5>
						<b-form-input v-model="password" type="password" name="password" autocomplete="current-password" value></b-form-input>
						<div class="row mx-0 d-flex justify-content-between my-3">
							<div class="m-0">
								<button class="btn btn-lg btn-primary m-0" type="submit" name="button">Login</button>
							</div>
							<div class="m-0 p-0">
								<button class="btn btn-lg btn-primary m-0" type="reset" @click="home()">{{ $t("Zur Karte") }}</button>
							</div>
						</div>
					</div>
				</b-form>
				<router-link to="/register" class="text-right color">
					<h5>
						{{ $t("Noch kein Konto? Hier Registrieren") }}
					</h5>
				</router-link>
				<router-link to="/resetpwd" class="text-right color">
					<h5>
						{{ $t("Passwort vergessen?") }}
					</h5>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import DataService from "@/services/DataService.js";
	export default {
		data() {
			return {
				email: "",
				password: "",
				error: null,
			};
		},
		computed: {
			...mapGetters(["itemsQuantity", "taxes", "total", "companyData"]),
		},
		methods: {
			login() {
				this.$store
					.dispatch("login", {
						LoginName: this.email,
						Password: this.password,
					})
					.then(() => {
						if (this.total > 0) {
							this.$router.push({ name: "CheckOut" });
						} else {
							this.$router.push({ name: "order" });
						}
					})
					.catch(() => {
						this.error = "Anmeldung fehlgeschlagen!";
					});
			},
			home() {
				this.$router.push({ name: "order" });
			},
		},
		beforeMount() {
			if (this.companyData.length != 0) {
				DataService.setStore(this.companyData.apiKey);
			}
		},
	};
</script>

<style scoped>
	h5 {
		margin-top: 5px;
	}
</style>
