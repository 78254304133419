<template>
	<div v-if="!$route.query.apikey">
		<BaseIcon name="target" width="30" height="30" class="mt-1 mr-2" />
		<h2 v-if="onDuty == 'closed'">{{ $t("Wir haben zur Zeit Geschlossen") }}</h2>
		<h2 v-else>{{ $t("Wir haben Heute Geschlossen") }}</h2>

		<div class="row mx-n4">
			<div class="col-lg-12 mx-0">
				<div class="panel p-2 mb-4">
					<div class="row">
						<div class="col-lg-12 p-3 text-center">
							<img v-bind:src="logoUrl" class="w-100" />
							<hr />
							<h5 v-if="onDuty == 'closed'" class="mt-3">
								{{ $t("In Kürze sind wir wieder zu den gewohnten Öffnungszeiten für Euch da") }}
							</h5>
							<h5 v-else class="mt-3">
								{{ $t("Ab Morgen sind wir wieder zu den gewohnten Öffnungszeiten für Euch da") }}
							</h5>
							<h4>{{ $t("Unsere normalen Öffnungszeiten") }}</h4>
							<div v-for="(item, index) in openHours" :key="index">
								<span class="color">{{ $t(item.weekDay) }}</span> :
								<span v-for="(item, index) in item.hours" :key="index">
									<span v-if="index > 0" class="color">
										{{ $t("und") }}
									</span>
									{{ item.fromHour }} {{ $t("bis") }} {{ item.toHour }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "Closed",
		components: {},
		data() {
			return {};
		},
		computed: {
			...mapGetters(["companyData"]),
			onDuty() {
				return this.companyData.onDuty;
			},
			openHours() {
				var data = this.companyData.openHours;
				var arrOut = [];
				if (data) {
					for (var i = 0; i < data.length; i++) {
						arrOut.push({
							weekDay: data[i].weekDay,
							hours: [
								{
									fromHour: data[i].fromHour,
									toHour: data[i].toHour,
								},
							],
						});
					}
				}

				const sorter = {
					montag: 1,
					dienstag: 2,
					mittwoch: 3,
					donnerstag: 4,
					freitag: 5,
					samstag: 6,
					sonntag: 7,
				};
				arrOut.sort(function sortByDay(a, b) {
					let day1 = a.weekDay.toLowerCase();
					let day2 = b.weekDay.toLowerCase();
					return sorter[day1] - sorter[day2];
				});

				var output = [];
				arrOut.forEach(function(item) {
					var existing = output.filter(function(v) {
						return v.weekDay == item.weekDay;
					});
					if (existing.length) {
						var existingIndex = output.indexOf(existing[0]);
						output[existingIndex].hours = output[existingIndex].hours.concat(item.hours);
					} else {
						if (typeof item.hours == "string") item.hours = [item.hours];
						output.push(item);
					}
				});

				return output;
			},
			logoUrl() {
				return this.companyData.logoUrl;
			},
		},
		methods: {
			account() {
				this.$router.push({ name: "account" });
			},
			orders() {
				this.$router.push({ name: "orders" });
			},
		},
	};
</script>
