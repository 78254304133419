<template>
	<div>
		<BaseIcon name="target" width="30" height="30" class="mt-1 mr-2" />
		<h2>{{ $t("Vielen Dank") }}</h2>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel p-2">
					<div class="row">
						<div class="col-lg-12 p-3 text-center">
							<p v-if="paytype == 4">{{ $t("payment_cash") }}</p>
							<p v-else>{{ $t("payment_success4") }}</p>
							<p>{{ $t("payment_success2") }}</p>
							<p>{{ $t("payment_success5") }}</p>
						</div>
					</div>
					<div class="row mx-1 d-flex justify-content-between my-1">
						<div class="m-0">
							<button class="btn btn-lg btn-primary m-0" @click="orders()">
								{{ $t("Bestellungen") }}
							</button>
						</div>
						<div class="m-0 p-0">
							<button class="btn btn-lg btn-primary m-0" @click="home()">
								{{ $t("Zur Karte") }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PaymentSuccess",
		components: {},
		data() {
			return {
				paytype: this.$route.query.paytype,
			};
		},
		methods: {
			home() {
				this.$router.push({ name: "order" });
			},
			orders() {
				this.$router.push({ name: "orders" });
			},
		},
	};
</script>
